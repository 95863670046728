import { useState } from 'react';

import { Loading } from '../../components/Loading';
import { useNotificationDataSource } from '../../components/Notification/Context';
import { useAsyncCall } from '../../hooks/useAsyncCall';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import {
  apiService,
  type ImportGamePackRequest,
} from '../../services/api-service';
import { NotificationType } from '../../types';
import { GamePackActionType } from '../../types/game';
import { err2s, randomString, uuidv4 } from '../../utils/common';

export function ImportGamePack(): JSX.Element | null {
  const [fileKey, setFileKey] = useState(randomString(10));
  const [payload, setPayload] = useState<unknown>(null);
  const notificationDataSource = useNotificationDataSource();
  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const file = (target.files as FileList)[0];
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      const data = event.target?.result;
      if (typeof data === 'string') {
        setPayload(JSON.parse(data));
      }
    });
    reader.readAsText(file);
  };

  const {
    state: { transformed },
    error,
    call: importGamePack,
  } = useAsyncCall(
    useLiveCallback(async (req: ImportGamePackRequest) => {
      return await apiService.gamePack.importGamePack(req);
    })
  );

  const onUpload = async () => {
    if (!payload) return;
    // the backend will validate the payload
    const resp = await importGamePack(payload as never);
    if (!resp) return;
    notificationDataSource.send({
      id: uuidv4(),
      toUserClientId: '',
      type: NotificationType.GamePackAction,
      createdAt: Date.now(),
      metadata: {
        actionType: GamePackActionType.PublishGamePack,
        gamePack: resp.data.gamePack,
      },
    });
    setFileKey(randomString(10));
  };

  return (
    <div className='w-full h-full pt-10 text-white px-10'>
      <div className='text-3xl font-bold'>Import Game Pack</div>
      <div className='my-4 text-tertiary'>
        <div>Notes:</div>
        <ul className='text-sms list-inside list-disc'>
          <li>The new game pack will be uploaded to the public library</li>
          <li>Tags will be removed, so it will be not featured</li>
          <li>All media will be duplicated</li>
          <li className='font-bold'>
            For GPv2, we only import the minimal required blocks for associated
            brands.
          </li>
        </ul>
      </div>
      <div className='flex flex-col gap-4'>
        <input
          type='file'
          key={fileKey}
          onChange={onFileChange}
          accept='.json'
        />
        <button
          type='button'
          className='btn-primary w-40 px-4 h-8 flex items-center justify-center'
          disabled={!payload || transformed.isRunning}
          onClick={onUpload}
        >
          {transformed.isRunning && (
            <Loading text='' imgClassName='w-5 w-5' containerClassName='mr-3' />
          )}
          <div>{transformed.isRunning ? 'Importing' : 'Import'}</div>
        </button>
        {error && <div className='text-sms text-red-002'>{err2s(error)}</div>}
      </div>
    </div>
  );
}
